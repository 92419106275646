import React from 'react';
import { getStorageValue } from 'utils/getStorageValue';
import { Redirect } from 'react-router-dom';
import * as routePath from 'constants/routePaths';

export function RedirectPricesTabs() {
  const active = getStorageValue('worldPriceMainTab', routePath.WORLD_PRICE_HISTORY_PATH);

  return <Redirect to={active} />;
}
