import { z } from 'zod';
import { FixationMethodsEnum, TimeLowerCaseTypeEnum } from 'constants/metalSale';

export const FixSellPriceEntity = z.object({
  metalPrice: z
    .object({
      date: z.string(),
      type: TimeLowerCaseTypeEnum,
      ouncePrice: z.number().or(z.null()).or(z.undefined()),
      gramPrice: z.number().or(z.null()).or(z.undefined()),
    })
    .or(z.null()),
  currencyPrice: z
    .object({
      date: z.string(),
      price: z.number().or(z.null()).or(z.undefined()),
    })
    .or(z.null()),
  type: FixationMethodsEnum,
});

export type FixSellPriceEntity = z.infer<typeof FixSellPriceEntity>;

export const SpotSellPriceEntity = z.object({
  metalPrice: z.number().or(z.null()),
  currencyPrice: z.number().or(z.null()),
  type: FixationMethodsEnum,
});

export type SpotSellPriceEntity = z.infer<typeof SpotSellPriceEntity>;
