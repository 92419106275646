import { z } from 'zod';
import { UserEntity } from 'models/api/wts/common/user';
import { RawTypeEntity } from 'models/api/wts/metalSale/rawType';
import { FixationItemEntity } from 'models/api/wts/metalSale/fixationItem';
import { CompanyGroupEntity } from 'models/api/wts/common/companyGroup';
import { ReserveEntity } from 'models/api/wts/metalSale/reserve';
import { FixationStatusEntity } from 'models/api/wts/metalSale/fixationStatus';
import { TriggersCountEntity } from '../common/triggersCount';

export const FixationEntity = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  buyCompanyGroup: CompanyGroupEntity,
  status: FixationStatusEntity,
  user: UserEntity,
  rawType: RawTypeEntity,
  items: z.array(FixationItemEntity),
  reserve: ReserveEntity.or(z.null()),
  triggersCount: TriggersCountEntity,
});

export type FixationEntity = z.infer<typeof FixationEntity>;
