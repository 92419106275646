import { ProductActiveListEntity } from 'models/api/wts/metalPurchase/productActiveList';
import { SalesTableState } from './types';
import { SaleTableStatusEnum } from 'constants/metalPurchase';

export function normalizeProductsActive(data: ProductActiveListEntity['activeProducts']): SalesTableState[] {
  return data.map((product) => ({
    id: product.id,
    product,
    sellCompanyGroup: product.discounts.map((item) => item.sellCompanyGroup),
    status: product.status as SaleTableStatusEnum,
    weight: (product.weight / product.totalCount) * product.availableCount,
    createdAt: product.createdAt,
    updatedAt: product.updatedAt,
    triggersCount: product.triggersCount,
  }));
}
