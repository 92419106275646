import { Action, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as actions from 'ducks/wts/metalPurchase/actions';
import { isNull } from 'lodash-es';
import { WtsPurchaseState } from './types';
import { EVENT_FIXATION_CREATED } from '../consts';

const initialState: WtsPurchaseState = {
  initData: null,
  loading: false,
  verification: {
    isVerified: false,
    role: null,
  },
  auth: {
    loading: false,
    authorized: false,
    error: null,
    isConnectWS: false,
  },
  productsList: { data: null, error: null },
  salesList: { data: null, error: null, loading: false },
  productsActiveList: { data: null, error: null, loading: false },
  metalTransfers: { data: [], error: null, loading: false },
  saleForm: { data: null, error: null },
  spotFixationData: [],
  loadingSpot: false,
  hasFilter: false,
  isMutationSubmitting: false,
  differentSyncTime: 0,
  isRefreshForm: false,
  saleTriggers: { data: null, error: null, loading: false },
};

const isPendingAction = (action: Action) =>
  action.type?.startsWith('wtsPurchase/') && action.type?.endsWith('/pending') && !action.type?.includes('ping');

const isRejectedAction = (action: Action) =>
  action.type?.startsWith('wtsPurchase/') && action.type?.endsWith('/rejected');

const isFulFilledAction = (action: Action) =>
  action.type?.startsWith('wtsPurchase/') && action.type?.endsWith('/fulfilled');

const slice = createSlice({
  name: 'wtsPurchase',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setupFailed(state, { payload }) {
      state.auth.error = payload;
      state.auth.isConnectWS = false;
    },
    setupOnReconnect(state, { payload }) {
      state.auth = {
        ...state.auth,
        loading: false,
        authid: payload.authid,
        companyGroupId: payload.companyGroupId,
        error: null,
        isConnectWS: true,
      };
    },
    onCloseWS(state) {
      state.auth.isConnectWS = false;
    },
    getSpotPrice(state, { payload }) {
      const index = state.spotFixationData.findIndex((item) => item.topic === payload.topic);

      if (payload?.event === EVENT_FIXATION_CREATED) {
        state.isRefreshForm = true;
        return;
      }

      if (index > -1) {
        if (payload?.context?.priceRUBPerUSD) {
          state.spotFixationData[index].currencyPrice = payload?.context;
        } else {
          state.spotFixationData[index].metalPrice = payload?.context;
        }
      }
    },
    getHasFilter(state, { payload }) {
      state.hasFilter = payload;
    },
    clearProductsList(state) {
      state.productsList = initialState.productsList;
    },
    clearMetalTransferList(state) {
      state.metalTransfers = initialState.metalTransfers;
    },
    clearSalesList(state) {
      state.salesList = initialState.salesList;
    },
    clearProductsActiveList(state) {
      state.salesList = initialState.salesList;
    },
    clearSaleFormData(state) {
      state.saleForm = initialState.saleForm;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.setupWTSService.pending, (state) => {
      state.auth = {
        ...state.auth,
        loading: true,
      };
    });
    addCase(actions.setupWTSService.fulfilled, (state, { payload }) => {
      state.auth = {
        // не меняем loading так как следом идет запрос верификации
        ...state.auth,
        authorized: true,
        authid: payload.authid,
        companyGroupId: payload.companyGroupId,
        error: null,
        isConnectWS: true,
      };
    });
    addCase(actions.setupWTSService.rejected, (state, { payload }) => {
      state.auth = {
        loading: false,
        authorized: false,
        error: payload,
      };
      state.verification = initialState.verification;
      state.initData = initialState.initData;
    });
    addCase(actions.getSaleForm.fulfilled, (state, { payload }) => {
      state.saleForm = { error: null, data: payload };
    });
    addCase(actions.getSaleForm.rejected, (state, { payload }) => {
      state.saleForm = { error: payload, data: null };
    });
    addCase(actions.getSaleForm.pending, (state) => {
      state.isRefreshForm = false;
    });
    addCase(actions.fixThePrice.fulfilled, () => {});
    addCase(actions.fixTheSpotPrice.fulfilled, () => {});
    addCase(actions.subscribeSpotPrice.fulfilled, (state, { payload }) => {
      state.spotFixationData = [...state.spotFixationData, payload] || [];
      state.loadingSpot = false;
    });
    addCase(actions.subscribeSpotPrice.pending, (state) => {
      state.loadingSpot = true;
    });
    addCase(actions.subscribeSpotPrice.rejected, (state) => {
      state.loadingSpot = false;
    });
    addCase(actions.unsubscribeSpotPrice.fulfilled, (state) => {
      state.spotFixationData = initialState.spotFixationData;
    });
    addCase(actions.verificationCheck.fulfilled, (state, { payload }) => {
      state.verification = payload.verification as WtsPurchaseState['verification'];
      state.auth.loading = false;
    });
    addCase(actions.getProductsList.fulfilled, (state, { payload }) => {
      state.productsList = { error: null, data: payload };
    });
    addCase(actions.getProductsList.rejected, (state, { payload }) => {
      state.productsList = { error: payload, data: null };
    });
    addCase(actions.getSalesList.fulfilled, (state, { payload }) => {
      state.salesList = { error: null, data: payload, loading: false };
    });
    addCase(actions.getSalesList.rejected, (state, { payload }) => {
      state.salesList = { error: payload, data: null, loading: false };
    });
    addCase(actions.getSalesList.pending, (state) => {
      state.salesList = { ...state.salesList, loading: true };
    });
    addCase(actions.getActiveProductsList.fulfilled, (state, { payload }) => {
      state.productsActiveList = { error: null, data: payload, loading: false };
    });
    addCase(actions.getActiveProductsList.rejected, (state, { payload }) => {
      state.productsActiveList = { error: payload, data: null, loading: false };
    });
    addCase(actions.getActiveProductsList.pending, (state) => {
      state.productsActiveList = { ...state.productsActiveList, loading: true };
    });
    addCase(actions.getInitSellFeatures.fulfilled, (state, { payload }) => {
      state.initData = payload;
    });
    addCase(actions.getMetalTransfers.fulfilled, (state, { payload }) => {
      state.metalTransfers = { error: null, data: payload, loading: false };
    });
    addCase(actions.getMetalTransfers.rejected, (state, { payload }) => {
      state.metalTransfers = { error: payload, data: [], loading: false };
    });
    addCase(actions.getMetalTransfers.pending, (state) => {
      state.metalTransfers = { ...state.metalTransfers, loading: true };
    });
    addCase(actions.ping.fulfilled, (state, { payload }) => {
      state.differentSyncTime = payload.diff;
    });
    addCase(actions.createTrigger.fulfilled, (state, { payload }) => {
      if (!isNull(state.saleForm.data)) {
        state.saleForm.data.triggers = [...state.saleForm.data.triggers, payload];
      }
      state.isMutationSubmitting = false;
    });
    addCase(actions.deleteTrigger.fulfilled, (state, { payload }) => {
      if (!isNull(state.saleForm.data)) {
        state.saleForm.data.triggers = state.saleForm.data?.triggers.filter(
          (item) => item.publicId !== payload.publicId
        );
      }
    });
    addCase(actions.getSaleTriggers.fulfilled, (state, { payload }) => {
      state.saleTriggers = { error: null, data: payload, loading: false };
    });
    addCase(actions.getSaleTriggers.rejected, (state, { payload }) => {
      state.saleTriggers = { error: payload, data: null, loading: false };
    });
    addCase(actions.getSaleTriggers.pending, (state) => {
      state.saleTriggers = { ...state.saleTriggers, loading: true };
    });
    addMatcher(
      (action) => isPendingAction(action),
      (state) => {
        state.loading = true;
      }
    );
    addMatcher(
      (action) => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state) => {
        state.loading = false;
      }
    );
    addMatcher(
      isAnyOf(
        actions.fixThePrice.pending,
        actions.cancelProduct.pending,
        actions.createProduct.pending,
        actions.createSale.pending,
        actions.fixTheSpotPrice.pending,
        actions.deleteFixation.pending,
        actions.createTrigger.pending
      ),
      (state) => {
        state.isMutationSubmitting = true;
      }
    );
    addMatcher(
      isAnyOf(
        actions.fixThePrice.rejected,
        actions.fixThePrice.fulfilled,
        actions.cancelProduct.rejected,
        actions.cancelProduct.fulfilled,
        actions.createProduct.fulfilled,
        actions.createProduct.rejected,
        actions.createSale.fulfilled,
        actions.createSale.rejected,
        actions.fixTheSpotPrice.fulfilled,
        actions.fixTheSpotPrice.rejected,
        actions.deleteFixation.fulfilled,
        actions.deleteFixation.rejected,
        actions.createTrigger.rejected
      ),
      (state) => {
        state.isMutationSubmitting = false;
      }
    );
  },
});

export const {
  setupFailed,
  onCloseWS,
  getSpotPrice,
  getHasFilter,
  resetState,
  setupOnReconnect,
  clearProductsList,
  clearMetalTransferList,
  clearSalesList,
  clearSaleFormData,
} = slice.actions;

export default slice.reducer;
