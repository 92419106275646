/* eslint-disable no-console */
import Wampy from 'wampy';
import { apiGet } from 'services/helper';
import { PROD_HOSTNAME } from 'features/wts/constants/consts';
import { RequestEntity } from 'models/params/wts/common/request';
import { AnyAction, Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { TokenEntity } from 'models/api/wts/common/token';
import { ConnectionSetupEntity } from 'models/api/wts/common/connectionSetup';
import { AuthIdParam } from 'models/params/wts/common/connectionSetup';
import { ActionsSetup } from 'models/params/wts/common/actionsSetup';
import { SubscribeActionSchema } from 'models/params/worldPrice/subcribePrice';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const wsUrl = (() => {
  const hostname = window.location.hostname;
  const isProduction = hostname === PROD_HOSTNAME;

  if (isProduction) {
    return `wss://wts.krastsvetmet.ru/ws`;
  }
  if (isLocalhost) {
    return process.env.WTS_ORIGIN as string;
  }
  const stageNumber = hostname.replace(/[^\d]/g, '');

  return `wss://stage${stageNumber}.wts-router.mtpdev.knfmp.net/ws`;
})();

class WTSServiceFactory {
  ws: Wampy.Wampy | null;
  userId: unknown;
  token: null | string;

  constructor() {
    this.ws = null;
    this.userId = null;
    this.token = null;
  }

  clear() {
    this.ws = null;
    this.userId = null;
  }

  async getToken() {
    try {
      const { data } = await apiGet<ApiResponseEntity<TokenEntity>>('/v1/wts/token');
      this.token = data?.data?.token;
    } catch (error: unknown) {
      return (error as AxiosError).response?.data;
    }
  }

  async setup(authid: AuthIdParam, dispatch: Dispatch, { setupOnReconnect, setupFailed, onCloseWS }: ActionsSetup) {
    return new Promise<ConnectionSetupEntity>(async (resolve, reject) => {
      if (authid && this.userId !== authid && !this.ws) {
        this.userId = authid;
        await this.getToken();
        if (this.token) {
          this.ws = new Wampy(wsUrl, {
            realm: 'wts.front',
            authid,
            authmethods: ['jwt'],
            autoReconnect: true,
            maxRetries: 0,
            reconnectInterval: 10000,
            // debug: true,
            onChallenge: () => {
              return this.token as string;
            },
            // @ts-expect-error Type is not assignable to type 'Callback'
            onConnect: (resultData: ConnectionSetupEntity) => {
              console.log('onConnect data: ', resultData);
              resolve(resultData);
            },
            // @ts-expect-error Type is not assignable to type 'Callback'
            onError: (error: unknown) => {
              console.log('onError', error);
              dispatch(setupFailed(error));
              reject(error);
            },
            // @ts-expect-error Type is not assignable to type 'Callback'.
            onClose: (data: unknown) => {
              console.log('onClose data: ', data);
              dispatch(onCloseWS());
              this.clear();
            },
            onReconnect: () => {
              this.getToken();
            },
            // @ts-expect-error Type is not assignable to type 'Callback'.
            onReconnectSuccess: async (data: unknown) => {
              console.log('Reconnect success:', data, this.ws?.getSessionId());
              await dispatch(setupOnReconnect(data));
            },
          });
        }
      }
    });
  }

  async subscribeWithDispatch(
    action: string,
    getAction: (arg: { topic: string; [key: string]: unknown }) => AnyAction,
    dispatch: Dispatch
  ) {
    return new Promise((resolve, reject) => {
      this.ws?.subscribe(action, {
        onEvent: ({ argsList }: { argsList: unknown[] }) => {
          dispatch(
            getAction(
              Object.assign({}, ...argsList, {
                topic: action.toString(),
              })
            )
          );
        },
        // @ts-expect-error TS2322: Type '(details: unknown) => void' is not assignable to type 'Callback'.
        onSuccess: (details: unknown) => {
          resolve(details);
        },
        onError: (error) => {
          console.error(error);
          reject(error.error);
        },
      });
    });
  }

  async subscribePrice(
    { type, topicUri }: SubscribeActionSchema,
    getAction: (arg: { topic: string; [key: string]: unknown }) => AnyAction,
    dispatch: Dispatch
  ) {
    return new Promise((resolve, reject) => {
      this.ws?.subscribe(topicUri, {
        onEvent: ({ argsList }: { argsList: unknown[] }) => {
          dispatch(
            getAction(
              Object.assign({}, ...argsList, {
                type,
              })
            )
          );
        },
        // @ts-expect-error TS2322: Type '(details: unknown) => void' is not assignable to type 'Callback'.
        onSuccess: (details: unknown) => {
          resolve(details);
        },
        onError: (error) => {
          console.error(error);
          reject(error.error);
        },
      });
    });
  }

  async subscribe(topic: string) {
    return new Promise((resolve, reject) => {
      this.ws?.subscribe(topic, {
        onEvent: (eventData: unknown) => {
          resolve(eventData);
        },
        onError: (error) => {
          console.log(topic, error.error);
          reject(error);
        },
      });
    });
  }

  async unsubscribe(topic: string) {
    return new Promise((resolve, reject) => {
      this.ws?.unsubscribe(topic, {
        // @ts-expect-error TS2322: Type is not assignable to type 'Callback'.
        onSuccess: (data: unknown) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error.error);
        },
      });
    });
  }

  destroy() {
    this.ws?.disconnect();
    this.clear();
  }

  /**
   * @deprecated Используйте метод callV2
   */
  call(action: string, payload: unknown = null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve, reject) => {
      this.ws?.call(action, [payload], {
        onSuccess: ({ argsList }) => {
          // @ts-expect-error TS2556: A spread argument must either have a tuple type or be passed to a rest parameter
          resolve(...argsList);
        },
        onError: (error) => {
          console.log(error);
          reject(error);
        },
      });
    });
  }

  callV2({ method = 'POST', action = 'com.wts.api.request', headers = [], path, body = null }: RequestEntity) {
    return this.call(action, {
      method,
      headers,
      path,
      body,
    });
  }
}

export const WTSService = new WTSServiceFactory();
