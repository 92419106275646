import { createAsyncThunk } from '@reduxjs/toolkit';
import { PricesService } from './service';
import { ConnectionSetupEntity } from 'models/api/wts/common/connectionSetup';
import { AuthIdParam } from 'models/params/wts/common/connectionSetup';
import { WTSService } from 'services/wts/wtsApi';
import { onCloseWS, setupFailed, setupOnReconnect } from './slice';
import { parseDataBySchema } from 'utils/parseDataBySchema';
import { ErrorEntity } from 'models/api/wts/metalSale/error';
import { closableMessage as antdMessage } from 'components/ClosableMessage';
import { PRICE_CHANNEL_API_PATH } from './consts';
import { PriceChannelListEntity } from 'models/api/worldPrices/priceChannel';
import { SubscribePriceParamsSchema } from 'models/params/worldPrice/subcribePrice';
import dayjs from 'dayjs';

export const getPrices = createAsyncThunk('worldPrices/getPrices', async (search: string) => {
  const { data, success, message } = await PricesService.getPrices(search);

  if (!success) {
    throw new Error(message);
  }

  return data;
});

type NextPagePriceParams = { search?: string; page: number };

export const getNextPagePrices = createAsyncThunk(
  'worldPrices/getNextPagePrices',
  async ({ search, page }: NextPagePriceParams) => {
    const { data, success, message } = await PricesService.getNextPagePrices(search, page);

    if (!success) {
      throw new Error(message);
    }

    return data;
  }
);

export const setupWs = createAsyncThunk<ConnectionSetupEntity, AuthIdParam>(
  'worldPrices/setupWs',
  async (userId, { rejectWithValue, dispatch }) => {
    const data = await WTSService.setup(userId, dispatch, { onCloseWS, setupOnReconnect, setupFailed });
    if (data && !data.error) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  }
);

export const destroyWs = createAsyncThunk('worldPrices/destroyWs', async () => {
  return WTSService.destroy();
});

export const getPriceChannels = createAsyncThunk<PriceChannelListEntity, undefined>(
  'worldPrices/getPriceChannels',
  async (_, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: PRICE_CHANNEL_API_PATH,
      method: 'GET',
    });

    const [result, zodError] = parseDataBySchema(PriceChannelListEntity, data);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    antdMessage.error(apiError?.message || 'Не удалось получить список каналов');

    return rejectWithValue(apiError || zodError || null);
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribePrice = createAsyncThunk<any, SubscribePriceParamsSchema>(
  'wtsPurchase/getSpotPrice',
  async ({ getAction, ...params }, { rejectWithValue, dispatch }) => {
    try {
      return await WTSService.subscribePrice(params, getAction, dispatch);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const unsubscribePrice = createAsyncThunk<unknown, string>(
  'worldPrices/unsubscribePrice',
  async (topic, { rejectWithValue }) => {
    try {
      return await WTSService.unsubscribe(topic);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const ping = createAsyncThunk(`worldPrices/ping`, async (_, { rejectWithValue }) => {
  try {
    const data = await WTSService.call(`com.wts.common.ping`);
    const diff = dayjs().diff(data.now, 'millisecond', true);

    return { ...data, diff: diff * -1 };
  } catch (error) {
    return rejectWithValue(error);
  }
});
