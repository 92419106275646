import { Centrifuge } from 'centrifuge';
import { isSupportEvent, ReadMessagesEventBody, SupportEventsEnum } from 'models/api/support/supportEvents';
import { TicketEntity } from 'models/api/support/ticket';
import { MessageEntity } from 'models/api/support/message';
import { SupportCounters } from 'models/api/support/counters';
import { State as CentrifugeStatus } from 'centrifuge/build/types';
import { log, getWsToken } from './utils';
import { SUPPORT_WS_URL } from 'services/ws/consts';

type SupportEventListener<D> = (data: D) => void;
type ChangeStatusHandler = (status: CentrifugeStatus) => void;

class SupportWsApi {
  private centrifuge: null | Centrifuge;
  private listenersByEvents: {
    CreateSupportTicket: SupportEventListener<TicketEntity>[];
    CreateSupportTicketMessage: SupportEventListener<MessageEntity>[];
    ReadSupportTicket: SupportEventListener<ReadMessagesEventBody>[];
    UpdateSupportTicket: SupportEventListener<TicketEntity>[];
    UpdateSupportCounters: SupportEventListener<SupportCounters>[];
  };

  constructor() {
    this.centrifuge = null;

    this.listenersByEvents = {
      CreateSupportTicket: [],
      CreateSupportTicketMessage: [],
      ReadSupportTicket: [],
      UpdateSupportTicket: [],
      UpdateSupportCounters: [],
    };
  }

  async connect(onStatusChange: ChangeStatusHandler) {
    this.disconnect();

    try {
      this.centrifuge = new Centrifuge(SUPPORT_WS_URL, {
        token: await getWsToken(),
        getToken: getWsToken,
      });

      this.centrifuge
        .on('connecting', function (ctx) {
          log('Connecting to Centrifugo', ctx);
          onStatusChange(CentrifugeStatus.Connecting);
        })
        .on('connected', function (ctx) {
          log('Connected to Centrifugo', ctx);
          onStatusChange(CentrifugeStatus.Connected);
        })
        .on('disconnected', function (ctx) {
          log('Disconnected from Centrifugo', ctx);
          onStatusChange(CentrifugeStatus.Disconnected);
        })
        .on('error', function (ctx) {
          log('Centrifugo error', ctx);
        })
        .on('publication', (ctx) => {
          if (isSupportEvent(ctx.data)) {
            this.listenersByEvents[ctx.data.type].forEach((listener) => listener(ctx.data.body));
          }
        });

      this.centrifuge.connect();
    } catch (err) {
      console.error(err);
    }
  }

  disconnect() {
    if (!this.centrifuge) {
      return;
    }

    this.centrifuge.disconnect();
    this.centrifuge = null;
  }

  subscribe(eventName: 'CreateSupportTicket', cb: SupportEventListener<TicketEntity>): void;
  subscribe(eventName: 'CreateSupportTicketMessage', cb: SupportEventListener<MessageEntity>): void;
  subscribe(eventName: 'ReadSupportTicket', cb: SupportEventListener<ReadMessagesEventBody>): void;
  subscribe(eventName: 'UpdateSupportTicket', cb: SupportEventListener<TicketEntity>): void;
  subscribe(eventName: 'UpdateSupportCounters', cb: SupportEventListener<SupportCounters>): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe(eventName: SupportEventsEnum, cb: SupportEventListener<any>) {
    this.listenersByEvents[eventName].push(cb);
  }

  unsubscribe(eventName: SupportEventsEnum, cb: SupportEventListener<never>) {
    this.listenersByEvents[eventName] = (this.listenersByEvents[eventName] as SupportEventListener<unknown>[]).filter(
      (listener) => listener !== cb
    );
  }
}

export const supportWsApi = new SupportWsApi();
