import { z } from 'zod';
import { RoleChannelEnum } from 'constants/worldPrices';

export const PriceChannelEntity = z.object({
  type: RoleChannelEnum,
  topic: z.string(),
});

export type PriceChannelEntity = z.infer<typeof PriceChannelEntity>;

export const PriceChannelListEntity = z.object({
  channels: z.array(PriceChannelEntity),
});

export type PriceChannelListEntity = z.infer<typeof PriceChannelListEntity>;
