import { Action, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as actions from 'ducks/wts/metalSale/actions';
import { WtsState } from 'ducks/wts/metalSale/types';
import { getReservePrice, isFixationRoute } from 'ducks/wts/metalSale/utils';
import { EVENT_FIXATION_CREATED } from '../consts';

const initialState: WtsState = {
  initData: null,
  loading: false,
  verification: {
    isVerified: false,
    role: null,
    canCreatePriceFixation: false,
  },
  auth: {
    loading: false,
    authorized: false,
    error: null,
    isConnectWS: false,
  },
  fixationlist: { items: [] },
  companies: [],
  rawTypes: [],
  metals: [],
  priceFixation: undefined,
  fixationInitData: [],
  fixationInitConfigs: [],
  spotFixationData: [],
  loadingSpot: false,
  hasFilter: false,
  reservesList: { data: null, error: null },
  reserveMetalForm: { data: null, error: null },
  isMutationSubmitting: false,
  differentSyncTime: 0,
  isRefreshForm: false,
};

const isPendingAction = (action: Action) =>
  action.type?.startsWith('wts/') && action.type?.endsWith('/pending') && !action.type?.includes('ping');

const isRejectedAction = (action: Action) => action.type?.startsWith('wts/') && action.type?.endsWith('/rejected');

const isFulFilledAction = (action: Action) => action.type?.startsWith('wts/') && action.type?.endsWith('/fulfilled');

const slice = createSlice({
  name: 'wts',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setupFailed(state, { payload }) {
      state.auth.error = payload;
      state.auth.isConnectWS = false;
    },
    setupOnReconnect(state, { payload }) {
      state.auth = {
        ...state.auth,
        loading: false,
        authid: payload.authid,
        companyGroupId: payload.companyGroupId,
        error: null,
        isConnectWS: true,
      };
    },
    onCloseWS(state) {
      state.auth.isConnectWS = false;
    },
    clearStepsData(state) {
      state.metals = [];
      state.priceFixation = undefined;
    },
    setRawType(state, { payload }) {
      state.rawTypes = [...state.rawTypes, payload];
    },
    getSpotPrice(state, { payload }) {
      const index = state.spotFixationData.findIndex((item) => item.topic === payload.topic);

      if (payload?.event === EVENT_FIXATION_CREATED) {
        state.isRefreshForm = true;
        return;
      }

      if (index > -1) {
        if (payload?.context?.priceRUBPerUSD) {
          state.spotFixationData[index].currencyPrice = payload?.context;
        } else {
          state.spotFixationData[index].metalPrice = payload?.context;
        }
      }
    },
    getHasFilter(state, { payload }) {
      state.hasFilter = payload;
    },
    clearFixationsList(state) {
      state.fixationlist = initialState.fixationlist;
    },
    clearReserveList(state) {
      state.reservesList = initialState.reservesList;
    },
    clearInitFormData(state) {
      state.fixationInitData = initialState.fixationInitData;
      state.fixationInitConfigs = initialState.fixationInitConfigs;
      state.priceFixation = initialState.priceFixation;
    },
    clearStartStepsData(state) {
      state.companies = [];
      state.rawTypes = [];
    },
    clearReserveMetalForm(state) {
      state.reserveMetalForm = initialState.reserveMetalForm;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(actions.setupWTSService.pending, (state) => {
      state.auth = {
        ...state.auth,
        loading: true,
      };
    });
    addCase(actions.setupWTSService.fulfilled, (state, { payload }) => {
      state.auth = {
        // не меняем loading так как следом идет запрос верификации
        ...state.auth,
        authorized: true,
        authid: payload.authid,
        companyGroupId: payload.companyGroupId,
        error: null,
        isConnectWS: true,
      };
    });
    addCase(actions.setupWTSService.rejected, (state, { payload }) => {
      state.auth = {
        loading: false,
        authorized: false,
        error: payload,
      };
      state.verification = initialState.verification;
      state.initData = initialState.initData;
    });
    addCase(actions.getFixationList.fulfilled, (state, { payload }) => {
      state.fixationlist = payload.paginator;
    });
    addCase(actions.getFixationList.rejected, (state) => {
      state.fixationlist = { items: [] };
    });
    addCase(actions.getCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload.companies || [];
    });
    addCase(actions.getRawTypes.pending, (state) => {
      state.metals = [];
      state.priceFixation = undefined;
    });
    addCase(actions.getRawTypes.fulfilled, (state, { payload }) => {
      state.rawTypes = payload?.rawTypes || [];
    });
    addCase(actions.getManageForm.fulfilled, (state, { payload }) => {
      state.metals = payload?.metals || [];
      state.priceFixation = payload?.priceFixation || undefined;
    });
    addCase(actions.getInitFixationForm.fulfilled, (state, { payload }) => {
      if (!isFixationRoute()) {
        return;
      }
      state.fixationInitData = payload.items || [];
      state.fixationInitConfigs = payload.configs || [];
      state.priceFixation = payload.fixation || undefined;
    });
    addCase(actions.getInitFixationForm.pending, (state) => {
      state.isRefreshForm = false;
    });
    addCase(actions.fixThePrice.fulfilled, (state, { payload }) => {
      state.fixationInitData = payload.items || [];
      state.fixationInitConfigs = payload.configs || [];
    });
    addCase(actions.fixTheKitcoPrice.fulfilled, (state, { payload }) => {
      state.fixationInitData = payload.items || [];
      state.fixationInitConfigs = payload.configs || [];
    });
    addCase(actions.fixTheSpotPrice.fulfilled, (state, { payload }) => {
      state.fixationInitData = payload.items || [];
      state.fixationInitConfigs = payload.configs || [];
    });
    addCase(actions.subscribeSpotPrice.fulfilled, (state, { payload }) => {
      state.spotFixationData = [...state.spotFixationData, payload] || [];
      state.loadingSpot = false;
    });
    addCase(actions.subscribeSpotPrice.pending, (state) => {
      state.loadingSpot = true;
    });
    addCase(actions.subscribeSpotPrice.rejected, (state) => {
      state.loadingSpot = false;
    });
    addCase(actions.unsubscribeSpotPrice.fulfilled, (state, { payload }) => {
      state.spotFixationData = state.spotFixationData.filter((item) => item.topic !== payload?.topic);
    });
    addCase(actions.verificationCheck.fulfilled, (state, { payload }) => {
      state.verification = payload.verification as WtsState['verification'];
      state.auth.loading = false;
    });
    addCase(actions.deleteFixation.fulfilled, (state, { payload }) => {
      state.fixationlist.items = state.fixationlist.items.filter((item) => item.id !== payload?.id);
    });
    addCase(actions.getReserveList.fulfilled, (state, { payload }) => {
      state.reservesList = { error: null, data: payload };
    });
    addCase(actions.getReserveList.rejected, (state, { payload }) => {
      state.reservesList = { error: payload, data: null };
    });
    addCase(actions.getInitBuyFeatures.fulfilled, (state, { payload }) => {
      state.initData = payload;
    });
    addCase(actions.getReserveMetalForm.fulfilled, (state, { payload }) => {
      state.reserveMetalForm = { error: null, data: payload };
    });
    addCase(actions.getReserveMetalForm.rejected, (state, { payload }) => {
      state.reserveMetalForm = { error: payload, data: null };
    });
    addCase(actions.updateReserveDiscount.fulfilled, ({ reserveMetalForm }, { meta }) => {
      if (!reserveMetalForm.data) {
        return;
      }
      const price = getReservePrice(reserveMetalForm.data, meta.arg.fixationContentId);
      if (price) {
        price.discount.value = meta.arg.discount;
      }
    });
    addCase(actions.updateReserveAmount.fulfilled, ({ reserveMetalForm }, { meta }) => {
      if (!reserveMetalForm.data) {
        return;
      }
      const price = getReservePrice(reserveMetalForm.data, meta.arg.fixationContentId);
      if (price) {
        price.amount.value = meta.arg.amount;
      }
    });
    addCase(actions.ping.fulfilled, (state, { payload }) => {
      state.differentSyncTime = payload.diff;
    });
    addCase(actions.createTrigger.fulfilled, (state, { payload }) => {
      const { id, ...restProps } = payload;
      const index = state.fixationInitData.findIndex((item) => item.fixationContentId === id);

      if (index > -1) {
        state.fixationInitData[index].triggers = [...state.fixationInitData[index].triggers, restProps];
      }
      state.isMutationSubmitting = false;
    });
    addCase(actions.deleteTrigger.fulfilled, (state, { payload }) => {
      const index = state.fixationInitData.findIndex((item) => item.fixationContentId === payload.id);

      if (index > -1) {
        state.fixationInitData[index].triggers = state.fixationInitData[index].triggers.filter(
          (item) => item.publicId !== payload.publicId
        );
      }
    });

    addMatcher(
      (action) => isPendingAction(action),
      (state) => {
        state.loading = true;
      }
    );
    addMatcher(
      (action) => {
        return isRejectedAction(action) || isFulFilledAction(action);
      },
      (state) => {
        state.loading = false;
      }
    );
    addMatcher(
      isAnyOf(
        actions.fixThePrice.pending,
        actions.fixTheKitcoPrice.pending,
        actions.cancelReserve.pending,
        actions.extendReserve.pending,
        actions.deleteReserve.pending,
        actions.approveReserve.pending,
        actions.addReserve.pending,
        actions.editReserve.pending,
        actions.addSupply.pending,
        actions.editSupply.pending,
        actions.fixTheSpotPrice.pending,
        actions.deleteFixation.pending,
        actions.createTrigger.pending
      ),
      (state) => {
        state.isMutationSubmitting = true;
      }
    );
    addMatcher(
      isAnyOf(
        actions.fixThePrice.rejected,
        actions.fixThePrice.fulfilled,
        actions.fixTheKitcoPrice.fulfilled,
        actions.fixTheKitcoPrice.rejected,
        actions.cancelReserve.rejected,
        actions.cancelReserve.fulfilled,
        actions.extendReserve.fulfilled,
        actions.extendReserve.rejected,
        actions.deleteReserve.fulfilled,
        actions.deleteReserve.rejected,
        actions.approveReserve.fulfilled,
        actions.approveReserve.rejected,
        actions.addReserve.fulfilled,
        actions.addReserve.rejected,
        actions.editReserve.fulfilled,
        actions.editReserve.rejected,
        actions.addSupply.fulfilled,
        actions.addSupply.rejected,
        actions.editSupply.fulfilled,
        actions.editSupply.rejected,
        actions.fixTheSpotPrice.fulfilled,
        actions.fixTheSpotPrice.rejected,
        actions.deleteFixation.fulfilled,
        actions.deleteFixation.rejected,
        actions.createTrigger.rejected
      ),
      (state) => {
        state.isMutationSubmitting = false;
      }
    );
  },
});

export const {
  setupFailed,
  onCloseWS,
  clearStepsData,
  clearInitFormData,
  setRawType,
  getSpotPrice,
  getHasFilter,
  resetState,
  setupOnReconnect,
  clearStartStepsData,
  clearReserveMetalForm,
  clearFixationsList,
  clearReserveList,
} = slice.actions;

export default slice.reducer;
