import { z } from 'zod';
import { TriggerActionTypeEnum, TriggerConditionTypeEnum, TriggerOfferTypeEnum } from 'constants/metalSale';

export const TriggerSchemaEntity = z.object({
  publicId: z.string(),
  actionType: TriggerActionTypeEnum,
  offerType: TriggerOfferTypeEnum,
  conditionType: TriggerConditionTypeEnum,
  price: z.number(),
  withDiscount: z.boolean(),
  expiresAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TriggerSchemaEntity = z.infer<typeof TriggerSchemaEntity>;
