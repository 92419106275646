import { z } from 'zod';
import { MetalEntity } from '../common/metal';
import { SuccessEntity } from '../common/success';
import { TriggerSchemaEntity } from '../common/triggerSchema';

export const SaleTriggerEntity = z.object({
  publicId: z.string(),
  metal: MetalEntity,
  weight: z.number(),
  triggers: z.array(TriggerSchemaEntity),
});

export type SaleTriggerEntity = z.infer<typeof SaleTriggerEntity>;

export const SaleTriggerListEntity = z
  .object({
    sales: z.array(SaleTriggerEntity),
  })
  .merge(SuccessEntity);

export type SaleTriggerListEntity = z.infer<typeof SaleTriggerListEntity>;
