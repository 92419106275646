import { z } from 'zod';

export const SpotMethodEntity = z.object({
  active: z.boolean(),
  endTime: z.string(),
  startNextSession: z.string(),
  canOfferCurrencyPerOunce: z.boolean(),
  canOfferRubPerGram: z.boolean(),
});

export type SpotMethodEntity = z.infer<typeof SpotMethodEntity>;
