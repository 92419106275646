import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';
import { MetalOffer, PriceChannel, WorldPriceState } from './types';
import { RoleChannelEnum } from 'constants/worldPrices';
import { WORLD_PRICE_SLICE_NAME } from './consts';
import { prepareMetals } from './utils';

const CODES = 'pricesCodesDesctop';
const CODES_MOBILE = 'pricesCodesMobile';

const initialState: WorldPriceState = {
  authWs: {
    loading: false,
    authorized: false,
    error: null,
    isConnectWS: false,
    authid: undefined,
  },
  spotPrices: {
    loading: false,
    channels: [],
    prices: {
      Seller: { metals: prepareMetals(RoleChannelEnum.enum.Seller), currencies: [] },
      Buyer: { metals: prepareMetals(RoleChannelEnum.enum.Buyer), currencies: [] },
      Guest: { metals: prepareMetals(RoleChannelEnum.enum.Guest), currencies: [] },
    },
  },
  differentSyncTime: 0,
  loading: false,
  loadingNext: false,
  list: [],
  filters: null,
  pagination: null,
  codes:
    localStorage.getItem(CODES) && localStorage.getItem(CODES) !== '' ? localStorage.getItem(CODES)?.split(',') : [],
  codesMobile: localStorage.getItem(CODES_MOBILE) ?? '',
};

const slice = createSlice({
  name: WORLD_PRICE_SLICE_NAME,
  initialState,
  reducers: {
    setFilters(state, { payload }) {
      state.codes = !payload.isMobile ? payload.codes : state.codes;
      state.codesMobile = payload.isMobile ? payload.codes : state.codesMobile;

      if (!payload.isMobile) {
        const sortOrder = [...state.filters?.metals, ...state.filters?.currencies].map(({ code }) => code);
        state.codes?.sort((a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        });
      }

      localStorage.setItem(payload.isMobile ? CODES_MOBILE : CODES, payload.codes);
    },
    setupFailed(state, { payload }) {
      state.authWs.error = payload;
      state.authWs.isConnectWS = false;
    },
    setupOnReconnect(state, { payload }) {
      state.authWs = {
        ...state.authWs,
        loading: false,
        authid: payload.authid,
        error: null,
        isConnectWS: true,
      };
    },
    onCloseWS(state) {
      state.authWs.isConnectWS = false;
    },
    getSpotPrice(state, { payload }) {
      const sign: string = payload.asset.split('_').shift();
      const priceType = payload.asset.split('_').at(-1);

      const updateCurrency = (list: PriceChannel[]) => {
        const index = list?.findIndex((item) => item.asset === payload.asset);
        if (index > -1) {
          list[index] = {
            ...list[index],
            ...payload,
          };
        } else {
          list.push({ ...payload });
        }

        return list;
      };

      const updateMetal = (list: MetalOffer[]) => {
        const index = list?.findIndex((item) => item.sign === sign);
        if (index > -1) {
          list[index] = {
            ...list[index],
            [priceType]: payload,
          };
        } else {
          list.push({ sign, type: payload.type, [priceType]: payload });
        }

        return list;
      };

      if (payload.asset.indexOf('_') < 0) {
        state.spotPrices.prices[payload.type as RoleChannelEnum].currencies = updateCurrency(
          state.spotPrices.prices[payload.type as RoleChannelEnum].currencies
        );
      } else {
        state.spotPrices.prices[payload.type as RoleChannelEnum].metals = updateMetal(
          state.spotPrices.prices[payload.type as RoleChannelEnum].metals
        );
      }
    },

    setDiscount(state, { payload }) {
      const { discount, type, metal } = payload;
      const addDiscount = (list: MetalOffer[]) => {
        const index = list?.findIndex((item) => item.sign === metal);
        if (index > -1) {
          list[index] = {
            ...list[index],
            discount,
          };
        }

        return list;
      };
      state.spotPrices.prices[type as RoleChannelEnum].metals = addDiscount(
        state.spotPrices.prices[type as RoleChannelEnum].metals
      );
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(actions.setupWs.pending, (state) => {
      state.authWs = {
        ...state.authWs,
        loading: true,
      };
    });
    addCase(actions.setupWs.fulfilled, (state, { payload }) => {
      state.authWs = {
        ...state.authWs,
        authorized: true,
        authid: payload.authid,
        error: null,
        isConnectWS: true,
      };
    });
    addCase(actions.setupWs.rejected, (state, { payload }) => {
      state.authWs = {
        loading: false,
        authorized: false,
        error: payload,
      };
    });
    addCase(actions.getPrices.pending, (state) => {
      state.loading = true;
    });
    addCase(actions.getPrices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.list = payload.prices.list;
      state.filters = payload.filters;
      state.pagination = payload.prices.pagination;
    });
    addCase(actions.getPrices.rejected, (state) => {
      state.loading = true;
    });
    addCase(actions.getNextPagePrices.pending, (state) => {
      state.loadingNext = true;
    });
    addCase(actions.getNextPagePrices.fulfilled, (state, { payload }) => {
      state.loadingNext = false;
      state.list = [...state.list, ...payload.prices.list];
      state.pagination = payload.prices.pagination;
    });
    addCase(actions.getNextPagePrices.rejected, (state) => {
      state.loadingNext = true;
    });
    addCase(actions.getPriceChannels.fulfilled, (state, { payload }) => {
      state.spotPrices.channels = payload.channels;
      state.spotPrices.loading = false;
    });
    addCase(actions.getPriceChannels.rejected, (state) => {
      state.spotPrices.loading = false;
    });
    addCase(actions.getPriceChannels.pending, (state) => {
      state.spotPrices.loading = true;
    });
    addCase(actions.ping.fulfilled, (state, { payload }) => {
      state.differentSyncTime = payload.diff;
    });
  },
});

export const { setFilters, getSpotPrice, setupFailed, setDiscount, setupOnReconnect, onCloseWS } = slice.actions;

export default slice.reducer;
