import { z } from 'zod';
import { MetalSignatureEnum } from 'constants/metalSale';
import { isAvailableSpot } from 'features/wts/utils/isAvailableSpot';

type MetalValue = {
  name: string;
  min: number;
  endText: string;
};
export type MetalValues = Record<MetalSignatureEnum, MetalValue>;

export const StatusTypesEnum = z.enum([
  'draftFixation',
  'draftReserve',
  'success',
  'error',
  'request',
  'warning',
  'info',
  'warningCircle',
  'cancelled',
  'warningTriangleYellow',
  'warningTriangleRed',
]);

export const METALS_VALUES: MetalValues = {
  Au: { name: 'Золото', min: 1, endText: 'зафиксировано' },
  Ag: { name: 'Серебро', min: 1, endText: 'зафиксировано' },
  Pt: { name: 'Платина', min: 1, endText: 'зафиксирована' },
  Pd: { name: 'Палладий', min: 1, endText: 'зафиксирован' },
  Rh: { name: 'Родий', min: 1, endText: 'зафиксирован' },
  Ir: { name: 'Иридий', min: 1, endText: 'зафиксирован' },
  Ru: { name: 'Рутений', min: 1, endText: 'зафиксирован' },
  Os: { name: 'Осмий', min: 1, endText: 'зафиксирован' },
  Re: { name: 'Рений', min: 1, endText: 'зафиксирован' },
};

export const PriceSignEnum = z.nativeEnum({
  usdPerOzt: '$/Oz',
  rubPerUsd: '₽/$',
  rubPerGm: '₽/хчг',
});

export const TIMEPICKER_FORMAT = 'HH:mm';
export const MOSCOW_ZONE = 'Europe/Moscow';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const FIXATION_METHODS = {
  spot: { name: 'Спот', isAvialable: isAvailableSpot },
  kitco: { name: 'Kitco (Ask + Bid)/2' },
  fix: { name: 'Лондонский фиксинг' },
};

export const TIMES_OF_DAY = {
  am: 'Утро',
  pm: 'Вечер',
};

export const TROY_OUNCE_VALUE = 31.1035;

export const FiltersEnum = {
  dateTo: 'dateTo',
  dateFrom: 'dateFrom',
  page: 'page',
};

export const PROD_HOSTNAME = 'lk.krastsvetmet.ru';

export type StatusTypesEnum = z.infer<typeof StatusTypesEnum>;

export const initialGroupedTriggers = {
  metal: { mail: { count: 0, triggers: [] }, fixation: { count: 0, triggers: [] } },
  currency: { mail: { count: 0, triggers: [] }, fixation: { count: 0, triggers: [] } },
  full: { mail: { count: 0, triggers: [] }, fixation: { count: 0, triggers: [] } },
};
