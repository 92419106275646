import arrow from './arrow.svg';
import bell from './bell.svg';
import bellOutlined from './bellOutlined.svg';
import check from './check.svg';
import checkOutlined from './checkOutlined.svg';
import clip from './clip.svg';
import clockOutlined from './clockOutlined.svg';
import company from './company.svg';
import cross from './cross.svg';
import fileOutlined from './fileOutlined.svg';
import filter from './filter.svg';
import filter2 from './filter-2.svg';
import gear from './gear.svg';
import message from './message.svg';
import penOutlined from './penOutlined.svg';
import penSquare from './penSquare.svg';
import phone from './phone.svg';
import plane from './plane.svg';
import questionOutline from './questionOutline.svg';
import search from './search.svg';
import starOutlined from './starOutlined.svg';
import user from './user.svg';
import userOutlined from './userOutlined.svg';
import warningTriangle from './warningTriangle.svg';
import file2 from './file-2.svg';
import file from './file.svg';
import download from './download.svg';
import calendar from './calendar.svg';
import box from 'icons/box.svg';
import draft from './draft.svg';
import warningCircle from './warningCircle.svg';
import warningCircle2 from './warningСircle2.svg';
import checkCircle from './checkCircle.svg';
import errorCircle from './errorCircle.svg';
import plus from './plus.svg';
import basket from './basket.svg';
import eyeNot from './eyeNot.svg';
import minusCircle from './minusCircle.svg';
import arrowReturn from './arrowReturn.svg';
import menu from './menu.svg';
import planeOutlined from './planeOutlined.svg';
import infoCircle from './infoCircle.svg';
import info from './info.svg';
import comment from './comment.svg';
import reject from './reject.svg';
import clock from './clock.svg';
import error from './error.svg';
import checkLarge from './checkLarge.svg';
import link2 from './link-2.svg';
import copy from './copy.svg';
import circleReturn from './circleReturn.svg';

export const iconsList = {
  arrow,
  bell,
  bellOutlined,
  check,
  checkOutlined,
  clip,
  clockOutlined,
  company,
  cross,
  fileOutlined,
  filter,
  filter2,
  gear,
  message,
  penOutlined,
  penSquare,
  phone,
  plane,
  questionOutline,
  search,
  starOutlined,
  user,
  userOutlined,
  warningTriangle,
  file,
  file2,
  download,
  calendar,
  draft,
  warningCircle,
  warningCircle2,
  checkCircle,
  errorCircle,
  plus,
  basket,
  eyeNot,
  minusCircle,
  arrowReturn,
  menu,
  planeOutlined,
  info,
  infoCircle,
  box,
  comment,
  reject,
  clock,
  error,
  checkLarge,
  link2,
  copy,
  circleReturn,
};
