import { z } from 'zod';

export const FixationStatusesEnum = z.nativeEnum({
  draft: 1,
  partial: 2,
  finished: 3,
} as const);
export type FixationStatusesEnum = z.infer<typeof FixationStatusesEnum>;

export const ReserveStatusesEnum = z.enum(['new', 'approved', 'canceled', 'expired']);
export type ReserveStatusesEnum = z.infer<typeof ReserveStatusesEnum>;

export const UserRoleEnum = z.enum(['client', 'manager']);
export type UserRoleEnum = z.infer<typeof UserRoleEnum>;

export const FixationMethodsEnum = z.enum(['spot', 'kitco', 'fix']);
export type FixationMethodsEnum = z.infer<typeof FixationMethodsEnum>;

export const MetalSignatureEnum = z.enum(['Au', 'Ag', 'Pt', 'Pd', 'Rh', 'Ir', 'Ru', 'Os', 'Re']);
export type MetalSignatureEnum = z.infer<typeof MetalSignatureEnum>;

export const DiscountTypesEnum = z.enum(['fixed', 'editable', 'hidden']);
export type DiscountTypesEnum = z.infer<typeof DiscountTypesEnum>;

export const TimeTypeEnum = z.enum(['AM', 'PM']);
export type TimeTypeEnum = z.infer<typeof TimeTypeEnum>;

export const TimeLowerCaseTypeEnum = z.enum(['am', 'pm']);
export type TimeLowerCaseTypeEnum = z.infer<typeof TimeLowerCaseTypeEnum>;

export const TriggerActionTypeEnum = z.enum(['mail', 'fixation']);
export type TriggerActionTypeEnum = z.infer<typeof TriggerActionTypeEnum>;

export const TriggerOfferTypeEnum = z.enum(['metal', 'currency', 'full']);
export type TriggerOfferTypeEnum = z.infer<typeof TriggerOfferTypeEnum>;

export const TriggerConditionTypeEnum = z.enum(['above', 'below']);
export type TriggerConditionTypeEnum = z.infer<typeof TriggerConditionTypeEnum>;
