import { z } from 'zod';
import { TriggersCountEntity } from '../common/triggersCount';
import { MetalTransferContentEntity } from './metalTransferContent';
import { NomenclatureEntity } from './nomenclature';
import { GroupDiscountEntity } from './groupDiscount';
import { ProductStatusEnum } from 'constants/metalPurchase';

export const ProductEntity = z.object({
  id: z.number(),
  metalTransferContent: MetalTransferContentEntity,
  nomenclature: NomenclatureEntity,
  discounts: z.array(GroupDiscountEntity),
  status: ProductStatusEnum,
  weight: z.number(),
  totalCount: z.number(),
  availableCount: z.number(),
  minCount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  expiresAt: z.string(),
  startAt: z.string(),
  triggersCount: TriggersCountEntity,
});

export type ProductEntity = z.infer<typeof ProductEntity>;
