import { RoleChannelEnum } from 'constants/worldPrices';
import { getStorageValue } from 'utils/getStorageValue';
import { MetalOffer } from './types';

const DISCOUNTS_STORAGE_KEY = 'worldPricesDiscount';

export const prepareMetals = (type: RoleChannelEnum) => {
  const storageDiscount = getStorageValue(DISCOUNTS_STORAGE_KEY, '[]');
  const savedDiscounts = JSON.parse(storageDiscount);

  return savedDiscounts?.filter((item: Pick<MetalOffer, 'discount' | 'type' | 'sign'>) => item.type === type);
};
